import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BreadcrumbGroup, BreadcrumbGroupProps, Button, Link, SideNavigationProps, SpaceBetween, TextContent } from "@cloudscape-design/components";

import '../styles/learning-detail-page.scss';
import { useArtifactDetails } from "src/components/providers/ArticleDetailsProvider";
import { ArticleDetailsApiType, SharedLinkApiType } from "src/types/useApi";
import { useApi } from "src/hooks/useApi";
import { ArticleDetails } from "src/types/learningDetails";
import { AppLayoutWrapper } from "./AppLayoutWrapper";
import { buildDetailsSideNav, buildOrderedSubtopicList, formatSubtopicTitle, buildHrefMap } from "src/utils/hierarchyUtils";
import { useTranslate } from "src/i18n/useTranslate";
import { ContentObjects } from "src/types/contentObject";
import { getLocaleDate } from "src/helpers";
import { useFavorites } from "src/components/providers/FavoritesProvider";
import FeedbackModal from "src/components/FeedbackModal";
import { useAuth } from "src/components/providers/AuthProvider";
import initialMetricsPublisher from "src/metrics";
import * as KatalMetrics from '@amzn/katal-metrics';
import { NAV_GRAPH_ROOT_NODE_ID, useNavGraph } from "src/components/providers/NavGraphProvider";
import { preferencesDefault, usePreferences } from "src/components/providers/PreferencesProvider";
import { NOTIFICATIONS, useNotifications } from "src/components/providers/NotificationsProvider";
import { useRecentlyViewed } from "src/components/providers/RecentlyViewedProvider";
import { isTablet } from 'react-device-detect';
import { PreferenceLocation } from "src/types/preferences";
import HotKeyButton from "src/components/HotKeyButton";
import { HOTKEY_SHORTCUT_LABEL } from 'src/constants';
import { PREVIOUS_HOTKEY_SYMBOL } from 'src/constants';
import { NEXT_HOTKEY_SYMBOL } from 'src/constants';

interface LearningArtifactDetailPageProps{
    hasOpenedInNewTab: boolean;
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}

const LearningArtifactDetailPage = ({
    hasOpenedInNewTab,
    setHasOpenedInNewTab,
}: LearningArtifactDetailPageProps) => {
    const navigate = useNavigate();
    const t = useTranslate();
    const location = useLocation();
    let params = useParams();
    const [sectionGuid, setSectionGuid] = useState("");

    const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
    const [subtopicIdsForPagination, setSubtopicIdsForPagination] = useState<string[]>([]);
    const [currentSubtopicIndexForPagination, setCurrentSubtopicIndexForPagination] = useState(0);
    const [currentSubtopicTitle, setCurrentSubtopicTitle] = useState<string>("");
    const [sideNavStructure, setSideNavStructure] = useState<SideNavigationProps.Item[]>([]);
    const [hrefMap, setHrefMap] = useState<Map<string, string>>(new Map());

    const { hash } = location;
    // remove "#/" from beginning of hash and optional appended slug
    const contentObjectLcmsXmlGUID = hash.substring(2).split('/')[0];

    let artifactId = params?.artifactId;

    const { breadcrumbs, name: articleName, dateUpdated: dateArticleUpdated } = useArtifactDetails();

    const [artifactData, setArtifactData] = useState<ArticleDetails>();

    const { addFavorite, deleteFavorite, isFavorite } = useFavorites();

    const { getBreadcrumbNamesFromPath } = useNavGraph();

    const { user } = useAuth();

    const { userPreferences } = usePreferences();
    const { publishNotification, dismissNotification } = useNotifications();
    const { addRecentlyViewed } = useRecentlyViewed();

    const { data: sharedLinkData, error: sharedLinkError, loading: sharedLinkLoading, makeRequest: getSharedLink }: SharedLinkApiType = useApi({
        method: 'GET',
    })

    const { makeRequest: getArticleDetails, data: articleData, loading: articleDataLoading, error: articleDataError }: ArticleDetailsApiType = useApi({
        url: `learning-artifacts/${artifactId}`,
        method: 'GET'
    });

    const crumbsFromNavGraph = getBreadcrumbNamesFromPath(articleData?.classifications.find(classification => classification.path.startsWith(`${NAV_GRAPH_ROOT_NODE_ID}/`))?.path);

    const breadcrumbsWithHome = (breadcrumbs?.length ? [
        { text: "Home", href: "/" },
        ...breadcrumbs.map(b => { return { text: b, href: window.location.href } }),
    ] : crumbsFromNavGraph?.length ? [
        { text: "Home", href: "/" },
        ...crumbsFromNavGraph.map(b => { return { text: b, href: window.location.href } }),
    ] : []) as BreadcrumbGroupProps.Item[];

    const documentMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('Document');
    const publishDocumentMetric = (targetSubtopicId?: string) => {
        const subtopicId = targetSubtopicId || contentObjectLcmsXmlGUID || '';
        documentMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.document', `hashed userId: ${user?.hashedUserId || ''} - documentId: ${artifactId || ''} - topicId: ${subtopicId}`));
    }

    useEffect(() => {
        getSharedLink({
            url: `shared-link/${artifactId}`
        });

        getArticleDetails({
            url: `learning-artifacts/${artifactId}`
        });
    }, [artifactId])

    useEffect(() => {
        if (sharedLinkError && ((!contentObjectLcmsXmlGUID && !articleDataLoading) || articleDataError)) {
            navigate('/not-found');
        }
    }, [sharedLinkError, articleDataError])

    useEffect(() => {
        if (!articleName || !dateArticleUpdated || !contentObjectLcmsXmlGUID) {
            setSubtopicIdsForPagination([]);
        }
    }, []);

    useEffect(() => {
        if(contentObjectLcmsXmlGUID && subtopicIdsForPagination.length){
            setCurrentSubtopicIndexForPagination(subtopicIdsForPagination.indexOf(contentObjectLcmsXmlGUID))
        }
    }, [contentObjectLcmsXmlGUID, subtopicIdsForPagination]);

    const navigateToFirstSubtopicIfNoneSelected = (newSideNavStructure: SideNavigationProps.Item[], newSubtopicIds: string[], hrefMap: Map<string, string>) => {
        const getIdOfFirstChildWithoutItems = (navSection: any): string => {
            // if section has items, search through first item to see if it also has items
            if (navSection.items?.length) {
                return getIdOfFirstChildWithoutItems(navSection?.items[0]);
                // if section does not have items, return navSection href which is `#/${lcmsXmlGUID}`
            } else {
                return navSection.href;
            }
        }

        // if we have content objects and don't currently have a sub-topic selected
        // change URL to have a sub-topic
        if (newSideNavStructure.length && !contentObjectLcmsXmlGUID) {
            const idOfFirstChildWithoutItems = getIdOfFirstChildWithoutItems(newSideNavStructure[0])
            navigate(idOfFirstChildWithoutItems);
            setCurrentSubtopicIndexForPagination(newSubtopicIds.indexOf(idOfFirstChildWithoutItems))
        } else {
            if (contentObjectLcmsXmlGUID) {
                setCurrentSubtopicIndexForPagination(subtopicIdsForPagination.indexOf(contentObjectLcmsXmlGUID))
                if (hrefMap.get(contentObjectLcmsXmlGUID)) {
                    navigate(hrefMap.get(contentObjectLcmsXmlGUID)!)
                }                
            }
        }
    }

    const findAndSetSubtopicGuidAndName = (newArtifactData?: ArticleDetails) => {
        const topLevelContentObjects = newArtifactData ? newArtifactData.contentObjects.lessons : artifactData?.contentObjects.lessons;
        let found = false;

        const searchForTopicIdInContentObject = (topics: ContentObjects[]) => {
            topics.forEach((co: ContentObjects) => {
                if (co.lcmsXmlGUID === contentObjectLcmsXmlGUID) {
                    setSectionGuid(co.lcmsXmlGUID);
                    setCurrentSubtopicTitle(co.name);
                    found = true;
                } else if (co.topics?.length && !found) {
                    searchForTopicIdInContentObject(co.topics)
                }
            })
        }

        if (topLevelContentObjects) {
            searchForTopicIdInContentObject(topLevelContentObjects);
        }
    }

    const navigateToNotFoundIfInvalidSubtopicSelected = (subtopicIds: string[]) => {
        /* 
            if we have a sectionId in url, and we have built subtopics
            if subtopics don't include sectionId go to not found
        */
        if (contentObjectLcmsXmlGUID && subtopicIds.length) {
            const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            const subtopicIsGuid = guidPattern.test(contentObjectLcmsXmlGUID);
            if (!subtopicIsGuid || !subtopicIds.includes(contentObjectLcmsXmlGUID)) {
                navigate('/not-found');
                return true;
            }
        }

    }

    const isDocumentWrongContent = (): boolean => {
        const artifactClassificationPaths = articleData?.classifications?.map(classification => classification.path);
        if (!artifactClassificationPaths?.length) {
            return true
        }

        const userPreferenceKeys = Object.keys(userPreferences);
        if (!userPreferenceKeys.length) {
            return false
        }

        if (userPreferences.language.name == 'English (US)') {
            if (!['English', 'English (United States)'].includes(articleData.languages)) {
                return true
            }
        } else if (userPreferences.language.name == 'French (France)') {
            if (articleData.languages !== 'French') {
                return true
            }
        } else if (userPreferences.language.name !== articleData.languages){
            return true
        }

        for (const userPreferenceKey of userPreferenceKeys) {
            if (!['role', 'site', 'country', 'region'].includes(userPreferenceKey)) continue;

            if(userPreferenceKey === 'site'){
                const siteClassificationPaths = artifactClassificationPaths.filter(path => path.startsWith('187') && path.split('/').filter(Boolean).length === 4);
                if(!siteClassificationPaths.length) continue;
            }
            
            const preferenceLocation: PreferenceLocation = userPreferences[userPreferenceKey  as 'role' | 'site' | 'country' | 'region']!;
            if (userPreferenceKey == 'role' || userPreferenceKey == 'site') {
                if (preferenceLocation?.path !== preferencesDefault[userPreferenceKey]?.path && !artifactClassificationPaths.includes(preferenceLocation?.path)) return true
            } else {
                if (!artifactClassificationPaths.includes(preferenceLocation.path)) return true
            }
        }

        return false
    }

    useEffect(() => {
        dismissNotification(NOTIFICATIONS.ARTIFACT_ROLE_WARNING);
        if (articleData && isDocumentWrongContent()) {
            publishNotification({
                type: 'warning',
                id: NOTIFICATIONS.ARTIFACT_ROLE_WARNING,
                content: (
                    <>
                        {t('artifact_preferences_warning', "It looks like this document isn't a good match for your preferences.")}
                    </>
                )
            });

            const wrongContentPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('WrongContent');
            wrongContentPublisher.publish(new KatalMetrics.Metric.String('gru.wrongContent.click', articleData.id.toString()));
        }
    }, [articleData, userPreferences, t]);

    useEffect(() => {
        if (articleData) {
            setArtifactData(articleData);

            const builtSideNavStructure = buildDetailsSideNav(articleData.contentObjects?.lessons || []);
            const builtHrefMap = buildHrefMap(articleData.contentObjects?.lessons || [])
            setSideNavStructure(builtSideNavStructure)
            setHrefMap(builtHrefMap);

            const subtopicIds = buildOrderedSubtopicList(articleData);
            setSubtopicIdsForPagination(subtopicIds);

            navigateToFirstSubtopicIfNoneSelected(builtSideNavStructure, subtopicIds, builtHrefMap);
            navigateToNotFoundIfInvalidSubtopicSelected(subtopicIds);
            findAndSetSubtopicGuidAndName(articleData);

            addRecentlyViewed(articleData.id);
            if (isTablet && artifactData && contentObjectLcmsXmlGUID && !hasOpenedInNewTab) {
                setHasOpenedInNewTab(true);
                window.open(`https://ar.bravais.com/document/${articleData.id}/learningObject/${contentObjectLcmsXmlGUID}/preview`, "_blank");
            }
            if (artifactData) {
                publishDocumentMetric();
            }
        }
    }, [articleData]);

    // when hash changes go find correct guid for iframe
    useEffect(() => {
        setSectionGuid("");
        findAndSetSubtopicGuidAndName();
        // Redirects to not-found page if topic id is wrong
        if (articleData && (artifactId === `${articleData.id}`)) {
            if (!navigateToNotFoundIfInvalidSubtopicSelected(subtopicIdsForPagination)) {
                if (isTablet && !hasOpenedInNewTab) {
                    setHasOpenedInNewTab(true);
                    window.open(`https://ar.bravais.com/document/${articleData.id}/learningObject/${contentObjectLcmsXmlGUID}/preview`, "_blank");
                }
            }
        }
    }, [hash])

    const dateUpdated = getLocaleDate(dateArticleUpdated || artifactData?.dateUpdated);

    const onSubtopicSelection = (subtopicDetails: CustomEvent<SideNavigationProps.FollowDetail>) => {
        setHasOpenedInNewTab(false);
        setCurrentSubtopicTitle(subtopicDetails.detail.text || "");
        setCurrentSubtopicIndexForPagination(subtopicIdsForPagination.indexOf(subtopicDetails.detail.href.substring(2)));
        publishDocumentMetric(subtopicDetails.detail.href.split('/')[1])
    }

    const goToPreviousSubtopic = () => {
        if (currentSubtopicIndexForPagination > 0) {
            setHasOpenedInNewTab(false);
            setCurrentSubtopicIndexForPagination(currentSubtopicIndexForPagination - 1);
            const targetSubtopicId = subtopicIdsForPagination[currentSubtopicIndexForPagination - 1];
            navigate(hrefMap.get(targetSubtopicId.toString())!);
            publishDocumentMetric(targetSubtopicId.toString());
        }
    }

    const goToNextSubtopic = () => {
        if (currentSubtopicIndexForPagination < subtopicIdsForPagination.length - 1) {
            setHasOpenedInNewTab(false);
            setCurrentSubtopicIndexForPagination(currentSubtopicIndexForPagination + 1);
            const targetSubtopicId = subtopicIdsForPagination[currentSubtopicIndexForPagination + 1];
            navigate(hrefMap.get(targetSubtopicId.toString())!);
            publishDocumentMetric(targetSubtopicId.toString())
        }
    }

    const iframeSrc = contentObjectLcmsXmlGUID ?
        (articleData?.id && contentObjectLcmsXmlGUID ? `https://ar.bravais.com/document/${articleData.id}/learningObject/${contentObjectLcmsXmlGUID}/preview` : '') :
        sharedLinkData?.token ? `https://ar.bravais.com/s/${sharedLinkData.token}` : ''

    const clickFavoriteButtonMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('ClickFavoriteButton');
    const clickFavoriteButton = (id: number) => {
        isFavorite(id) ? deleteFavorite(id) : addFavorite(id);

        const documentMetricKey = isFavorite(id) ? 'gru.favorite.add.document' : 'gru.favorite.remove.document';
        const userMetricKey = isFavorite(id) ? 'gru.favorite.add.user' : 'gru.favorite.remove.user';

        clickFavoriteButtonMetricsPublisher.publish(new KatalMetrics.Metric.String(documentMetricKey, id.toString()));
        clickFavoriteButtonMetricsPublisher.publish(new KatalMetrics.Metric.String(userMetricKey, user?.hashedUserId || ''));
    }

    const DetailsPage = (
        <div>
            <SpaceBetween size="s">
                <div className="title-container">
                    <TextContent data-details-title>
                        <h1>{articleName || artifactData?.name}</h1>
                    </TextContent>
                    <div className="icon-container">
                        {
                            artifactData?.id && (
                                <Button
                                    iconName="heart"
                                    data-margin-right=".5em"
                                    variant="icon"
                                    data-heart-icon-filled={isFavorite(artifactData.id) ? "true" : "false"}
                                    data-testid={isFavorite(artifactData.id) ? 'filled-heart' : 'empty-heart'}
                                    data-heart-icon
                                    iconAlt={isFavorite(artifactData.id) ? t('remove-from-favorites', "Remove from Favorites") : t('add-to-favorites', "Add to Favorites")}
                                    onClick={() => { clickFavoriteButton(artifactData.id) }}
                                />
                            )
                        }
                        <Button
                            iconName="contact"
                            variant="icon"
                            data-feedback-icon
                            data-testid="open-feedback-icon"
                            iconAlt={t('open-feedback-modal', "Open feedback modal")}
                            onClick={() => setFeedbackModalOpen(true)}
                        />
                    </div>
                </div>
                <div className="last-modified-container">
                    <TextContent>
                        <small>{t("details-page-last-modified", "Last modified on")} <small data-last-modified-date>{dateUpdated}</small></small>
                    </TextContent>
                </div>
            </SpaceBetween>

            {isTablet
                ? <Link 
                    external 
                    href={`${iframeSrc}`}
                    variant="primary"
                >
                    {t('new-tab-content-launch', "Click here to launch the content in a new tab")}
                </Link>
                : <iframe
                    width="100%"
                    height="600"
                    src={iframeSrc}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    className="lesson-iframe"
                    data-testid="lesson-iframe"
                    loading="lazy"
                />
            }

            <div className="button-container" style={{ justifyContent: 'flex-end', display: 'flex', gap: '16px' }}>
                <HotKeyButton 
                    disabled={!(currentSubtopicIndexForPagination > 0)} 
                    onClick={() => goToPreviousSubtopic()} 
                    data-testid="details-previous-button"
                    hotKeyIdentifier="ArrowLeft"
                >
                    {t('annotation-context-previous', "Previous")} ({HOTKEY_SHORTCUT_LABEL} <span style={{ fontSize: '13px' }}>{PREVIOUS_HOTKEY_SYMBOL}</span>)
                </HotKeyButton>
                <HotKeyButton 
                    disabled={!(currentSubtopicIndexForPagination < subtopicIdsForPagination.length - 1)}
                    onClick={() => goToNextSubtopic()} 
                    variant="primary"
                    data-testid="details-next-button"
                    hotKeyIdentifier="ArrowRight"
                >
                    {t('annotation-context-next', "Next")} ({HOTKEY_SHORTCUT_LABEL} <span style={{ fontSize: '13px' }}>{NEXT_HOTKEY_SYMBOL}</span>)
                </HotKeyButton>
            </div>
        </div>
    );

    return (
        <SpaceBetween size="s" data-details-page>
            <FeedbackModal
                feedbackModalOpen={feedbackModalOpen}
                setFeedbackModalOpen={setFeedbackModalOpen}
                topicId={contentObjectLcmsXmlGUID}
                topicTitle={currentSubtopicTitle || articleName || artifactData?.name}
                artifactTitle={articleName || artifactData?.name!}
                artifactId={artifactId!}
            />
            <div className="details-breadcrumbs-and-icons-container">
                <div className="breadcrumbs-container">
                    <BreadcrumbGroup
                        items={breadcrumbsWithHome}
                        ariaLabel="Breadcrumbs"
                    />

                </div>
            </div>
            <AppLayoutWrapper content={DetailsPage} sideNavStructure={sideNavStructure} hrefMap={hrefMap} onSubtopicSelection={onSubtopicSelection} isDetailsPage />
        </SpaceBetween>
    )
};

export default LearningArtifactDetailPage;